import React from "react";

function Footer() {
  return (
    <div className="footer">
      <p className="footer-text">Benzi Holler &#xA9; 2024, Tucson, AZ</p>
    </div>
  );
}

export default Footer;
